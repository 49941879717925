import React from "react"
import { ImBin2 } from "react-icons/im";
import { RiEditBoxFill } from "react-icons/ri";
import Api from "../../../services/Api";
import { Link, useNavigate } from "react-router-dom"

const ListEquipamentoForm = (props) => {
  const navigate = useNavigate()
  const { data, handleListEquipaments } = props

  const usuario = JSON.parse(localStorage.getItem('usuario'))
  const token = usuario.token

  const handleConfirmBin = (name, id) => {
    var validate = window.confirm(`Confirma apagar o equipamento da empresa ${name}?`)
    if (validate === true) {
      handleExcludeEquipamentos(id)
    }
  }

  const handleExcludeEquipamentos = async (id) => {
    try {
      const data = await Api.delete('/instruments/deleteinstruments', { data: { "_id": id }, headers: { 'x-access-token': token } })
      if (data.status === 200) {
        handleListEquipaments()
      }
    } catch (err) {
      alert(err)
    }
  }

  const handleEditEquipament = (id) => {
    navigate(`editequipamento/${id}`);
  }

  return (
    <tr>
      <td className='col-sm-3 pe-auto' onClick={() => { handleEditEquipament(data._id) }}> {data.nameEmpresa} </td>
      <td className='col-sm-3 pe-auto' onClick={() => { handleEditEquipament(data._id) }}> {data.name}</td>
      <td className='col-sm-2 pe-auto' onClick={() => { handleEditEquipament(data._id) }}> {data.equipamentos.entrada.ns} </td>
      <td className='col-sm-2 pe-auto' onClick={() => { handleEditEquipament(data._id) }}> {data.equipamentos.saida.ns} </td>
      <td className='col-sm-1 text-center ico-bin' >
        <Link style={{"color": "#212529"}} to={`editequipamento/${data._id}`}> <RiEditBoxFill /> </Link>
      </td>
      <td className='col-sm-1 text-center ico-bin' onClick={() => {
        handleConfirmBin(data.nameEmpresa, data._id)
      }} > <ImBin2 />
      </td>
    </tr>
  )
}
export default ListEquipamentoForm
