import React from "react"
import { ImBin2 } from "react-icons/im";
import Api from "../../../services/Api";
import { Link, useNavigate } from "react-router-dom"
import { RiEditBoxFill } from "react-icons/ri";

const ListUserForm = (props) => {
  const navigate = useNavigate()
  const { data, handleListUsers } = props
  const usuario = JSON.parse(localStorage.getItem('usuario'))
  const token = usuario.token

  const handleConfirm = (name, id) => {
    var validate = window.confirm(`Confirma apagar usuário ${name}?`)
    if (validate === true) {
      handleExcludeUser(id)
    }
  }

  const handleExcludeUser = async (id) => {
    try {
      const data = await Api.delete('/usersadmin/deleteusersadmin', { data: { "_id": id }, headers:{'x-access-token': token} })
      if(data.status === 200){
        handleListUsers(id)
      }
    } catch (err) { 
      alert(err)
    }
  }
  
  const handleEditUser = (id) => {
    navigate(`edituser/${id}`);
  }

  return (
    <tr>
      <td className='col-sm-4 pe-auto' onClick={() => { handleEditUser(data._id) }}>{data.name}</td>
      <td className='col-sm-4 pe-auto' onClick={() => { handleEditUser(data._id) }}> {data.email}</td>
      <td className='col-sm-2 text-center ico-bin' >
        <Link style={{"color": "#212529"}} to={`edituser/${data._id}`}> <RiEditBoxFill /> </Link>
      </td>
      <td className='col-sm-2 text-center ico-bin' onClick={() => {
        handleConfirm(data.name, data._id)
      }} > <ImBin2 /> </td>
    </tr>
  )
}
export default ListUserForm
