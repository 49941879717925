import React from "react"
import Api from "../../services/Api"
import { Card } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { useEffect } from "react";
import { useState } from "react";
import ListUserForm from "./components/ListUserForm";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom"
import Button from 'react-bootstrap/Button';
import { AiOutlinePlusCircle } from "react-icons/ai";
import ButtonBack from "../../components/ButtonBack/ButtonBack";

const ListUser = (props) => {
  const navigate = useNavigate()
  const usuario = JSON.parse(localStorage.getItem('usuario'))
  const token = usuario.token
  const [user, setUser] = useState()

  const handleListUsers = async () => {
    try {
      const { data } = await Api.get('/usersadmin/listusersadmin', { headers: { 'x-access-token': token } })
      data ? setUser(data) : setUser([])
    } catch (err) {
      alert('Erro ao listar usuários.')
    }
  }

  useEffect(() => {
    handleListUsers()
  }, [])

  return (
    <>
      <Header />
      <div className='container mt-3 mb-3'>
        <Card className='p-3 mb-3'>
          <h2>Lista de usuários cadastrados</h2>
          <Table striped bordered hover style={{ "background": "white", "borderRadius": "5px", "border": "transparent" }}>
            <thead style={{ "background": "rgba(41, 140, 101, 0.6)" }}>
              <tr>
                <th className='col-sm-4'> Nome </th>
                <th className='col-sm-4'> E-mail </th>
                <th className='col-sm-2 text-center'> Editar </th>
                <th className='col-sm-2 text-center'> Apagar </th>
              </tr>
            </thead>
            <tbody>
              {user &&
                user.map((item) => (
                  <ListUserForm key={item._id} handleListUsers={handleListUsers} data={item} />
                ))
              }
            </tbody>
          </Table>
        </Card>
        <ButtonBack />
        <Button className='col-sm-2' variant="success" size='sm' onClick={() => { navigate('/adduser') }} >
          <AiOutlinePlusCircle /> Adicionar
        </Button>
      </div>
    </>
  )
}
export default ListUser
