import React from 'react'
import NavBar from '../NavBar/NavBar'

const Header = () => {

  return (
    <header>
      <NavBar />
    </header>
  )
}
export default Header