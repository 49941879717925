import React, { useEffect, useState } from "react"
import Api from "../../services/Api"
import { Card } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import ListEmpresaForm from "./components/ListEmpresaForm";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom"
import Button from 'react-bootstrap/Button';
import { AiOutlinePlusCircle } from "react-icons/ai";
import ButtonBack from "../../components/ButtonBack/ButtonBack";

const ListEmpresa = (props) => {
  const navigate = useNavigate()
  const usuario = JSON.parse(localStorage.getItem('usuario'))
  const token = usuario.token
  const [empresa, setEmpresa] = useState()

  const handleListEmpresas = async () => {
    try {
      const { data } = await Api.get('/empresas/listempresas', { headers: { 'x-access-token': token } })
      data ? setEmpresa(data) : setEmpresa([])
    } catch (err) {
      alert('Erro ao listar empresas.')
    }
  }

  useEffect(() => {
    handleListEmpresas()
  }, [])

  return (
    <>
      <Header />
      <div className='container mt-3 mb-3'>
        <Card className='p-3 mb-3'>
          <h2>Lista de empresas cadastradas</h2>
          <Table striped bordered hover style={{ "background": "white", "borderRadius": "5px", "border": "transparent" }}>
            <thead style={{ "background": "rgba(41, 140, 101, 0.6)" }}>
              <tr>
                <th className='col-sm-3'> Nome </th>
                <th className='col-sm-3'> E-mail </th>
                <th className='col-sm-2'> ID da empresa </th>
                <th className='col-sm-2'> CNPJ </th>
                <th className='col-sm-1 text-center'> Editar </th>
                <th className='col-sm-1 text-center'> Apagar </th>
              </tr>
            </thead>
            <tbody>
              {empresa &&
                empresa.map((item) => (
                  <ListEmpresaForm key={item._id} handleListEmpresas={handleListEmpresas} data={item} />
                ))
              }
            </tbody>
          </Table>
        </Card>
        <ButtonBack />
        <Button className='col-sm-2' variant="success" size='sm' onClick={() => { navigate('/addempresa') }} >
          <AiOutlinePlusCircle /> Adicionar
        </Button>
      </div>
    </>
  )
}
export default ListEmpresa
