import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Fragment } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import useAuth from './hooks/useAuth'
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home'
import Login from './pages/Login/Login'
import { createBrowserHistory } from 'history';
import EditarEmpresa from './pages/EditarEmpresa/EditarEmpresa';
import EditarEquipamento from './pages/EditarEquipamento/EditarEquipamento';
import AddEquipamento from './pages/AddEquipamento/AddEquipamento';
import AddEmpresa from './pages/AddEmpresa/AddEmpresa';
import ListEmpresa from './pages/ListEmpresa/ListEmpresa';
import ListEquipamento from './pages/ListEquipamento/ListEquipamento';
import AddUser from './pages/AddUser/AddUser';
import EditarUser from './pages/EditarUser/EditarUser';
import ListUser from './pages/ListUser/ListUser';
import { checkAuth } from './hooks/actions/sessionActions';

const history = createBrowserHistory();

const App = () => {
  const Private = ({ Item }) => {
    const signed = useAuth();
    const session = checkAuth();
    return !signed && session ? <Item /> : <Login />;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Fragment>
          <Routes history={history}>
            <Route exact path="/home" element={<Private Item={Home} />} />

            <Route exact path="/addequipamento" element={<Private Item={AddEquipamento} />} />
            <Route exact path="/adduser" element={<Private Item={AddUser} />} />
            <Route exact path="/addempresa" element={<Private Item={AddEmpresa} />} />

            <Route exact path="/home/editempresa/:_id" element={<Private Item={EditarEmpresa} />} />

            <Route exact path="/listempresa/editempresa/:_id" element={<Private Item={EditarEmpresa} />} />
            <Route exact path="/listuser/edituser/:_id" element={<Private Item={EditarUser} />} />
            <Route exact path="/listequipamento/editequipamento/:_id" element={<Private Item={EditarEquipamento} />} />

            <Route exact path="/listempresa" element={<Private Item={ListEmpresa} />} />
            <Route exact path="/listuser" element={<Private Item={ListUser} />} />
            <Route exact path="/listequipamento" element={<Private Item={ListEquipamento} />} />

            <Route path="*" element={<Login />} />
          </Routes>
          <Footer />
        </Fragment>
      </BrowserRouter>
    </div>
  )
}
export default App;
