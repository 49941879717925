import React from "react"
import { ImBin2 } from "react-icons/im";
import Api from "../../../services/Api";
import { Link, useNavigate } from "react-router-dom"
import { RiEditBoxFill } from "react-icons/ri";


const ListEmpresaForm = (props) => {
  const navigate = useNavigate()
  const { data, handleListEmpresas } = props
  const usuario = JSON.parse(localStorage.getItem('usuario'))
  const token = usuario.token


  const handleConfirm = (name, id) => {
    var validate = window.confirm(`Confirma apagar a empresa ${name}?`)
    if (validate === true) {
      handleExcludeEmpresa(id)
    }
  }

  const handleExcludeEmpresa = async (id) => {
    try {
      const data = await Api.delete('/empresas/deleteempresas', { data: { "_id": id }, headers: { 'x-access-token': token } })
      if (data.status === 200) {
        handleExcludeEquipamento(id)
      }
    } catch (err) {
      alert(err)
    }
  }

  const handleExcludeEquipamento = async (id) => {
    try {
      const data = await Api.delete('/instruments/deleteinstrumentoid', { data: { "_id": id }, headers: { 'x-access-token': token } })
      if (data.status === 200) {
        handleListEmpresas()
      }
    } catch (err) {
      alert(err)
    }
  }

  const handleEditCompany = (id) => {
    navigate(`editempresa/${id}`);
  }

  return (
    <tr>
      <td className='col-sm-3 pe-auto' onClick={() => { handleEditCompany(data._id) }}>{data.name}</td>
      <td className='col-sm-3 pe-auto' onClick={() => { handleEditCompany(data._id) }}> {data.email}</td>
      <td className='col-sm-2 pe-auto' onClick={() => { handleEditCompany(data._id) }}> {data._id}</td>
      <td className='col-sm-2 pe-auto' onClick={() => { handleEditCompany(data._id) }}> {data.cnpj}</td>
      <td className='col-sm-1 text-center ico-bin' >
        <Link style={{ "color": "#212529" }} to={`editempresa/${data._id}`}> <RiEditBoxFill /> </Link>
      </td>
      <td className='col-sm-1 text-center ico-bin' onClick={() => {
        handleConfirm(data.name, data._id)
      }} > <ImBin2 /> </td>
    </tr>
  )
}
export default ListEmpresaForm
