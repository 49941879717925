import React, { useState } from 'react';
import ListEmpresa from '../ListEmpresa/ListEmpresa'

//import Form from 'react-bootstrap/Form';
//import Card from 'react-bootstrap/Card';
//import Header from '../../components/Header/Header';

const Home = () => {
  const [usuario] = useState(JSON.parse(localStorage.getItem('usuario')));

  return (
    <>
      <ListEmpresa />

      {/*
      <div className='container mt-3 mb-3'>
        <Card className='p-3'>

          <Form.Label className='mb-0'>Nome</Form.Label>
          <Form.Control
            type="text"
            id="name"
            aria-describedby="name"
            value={usuario.name}
            disabled
          />

          <Form.Label className='mt-3 mb-0'>E-mail</Form.Label>
          <Form.Control
            type="text"
            id="name"
            aria-describedby="name"
            value={usuario.email}
            disabled
          />

          <Form.Label className='mt-3 mb-0'>ID</Form.Label>
          <Form.Control
            type="text"
            id="name"
            aria-describedby="name"
            value={usuario._id}
            disabled
          />
        </Card>
      </div>
       */}
    </>
  )
}
export default Home