import styled from "styled-components";

export const Input = styled.input`
  height: 44px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top: 1px solid silver;
  padding: 0 8px;
  box-sizing: border-box;
  border-radius: 8px
`;
