import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import Api from "../../services/Api"
import { Card, Col, Row, Button, Form } from 'react-bootstrap';
import { BsSave2 } from "react-icons/bs";
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import Header from "../../components/Header/Header";

const EditarEquipamento = (props) => {
  const { _id } = useParams()
  const navigate = useNavigate()

  const usuario = JSON.parse(localStorage.getItem('usuario'))
  const token = usuario.token

  const [equipament, setEquipament] = useState()
  const [empresaId, setEmpresaId] = useState()

  const handleEquipamento = async () => {
    try {
      const { data } = await Api.get(`/instruments/listinstruments/${_id}`, { headers: { 'x-access-token': token } })
      data ? setEquipament(data) : setEquipament([])
    } catch (err) {
      alert('Erro ao listar equipamento.')
    }
  }

  const handleEmpredaId = async () => {
    try {
      const { data } = await Api.get('/empresas/listempresas', { headers: { 'x-access-token': token } })
      data ? setEmpresaId(data) : setEmpresaId([])
    } catch (err) {
      alert('Erro ao listar equipamento.')
    }
  }

  const handlePutEquipament = async () => {
    try {
      const { data } = await Api.put('/instruments/updateinstruments', equipament, { headers: { 'x-access-token': token } })
      if (data) {
        navigate("/listequipamento")
      }
    } catch (err) {
      alert('Erro ao atualizar equipamento.')
    }
  }

  const handleValidateData = () => {
    if (equipament.name === '' || equipament.empresaId === '') {
      alert('Preencha todos os campos.')
    } else {
      handlePutEquipament()
    }
  }

  useEffect(() => {
    handleEquipamento()
    handleEmpredaId()
  }, [])

  return (
    <>
      <Header />
      <div className="container mt-3 mb-3">
        <Card className='p-3'>
          <h2>Editar equipamento</h2>
          {equipament &&
            <Row className="col-sm-12">
              <div className="col-sm-12">
                <Form.Label className='mb-0'>Nome</Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  aria-describedby="name"
                  onChange={(event) => {
                    equipament.name = event.target.value;
                    setEquipament({ ...equipament });
                  }}
                  value={equipament.name}
                  required
                />
              </div>
              <div className="col-sm-4 mt-3">
                <Form.Label className='mb-0'>Número de série Entrada</Form.Label>
                <Form.Control
                  type="text"
                  id="ns"
                  aria-describedby="ns"
                  onChange={(event) => {
                    equipament.equipamentos.entrada.ns = event.target.value.toUpperCase();
                    setEquipament({ ...equipament });
                  }}
                  value={equipament.equipamentos.entrada.ns}
                  required
                />
              </div>

              <div className="col-sm-2 mt-3">
                <Form.Label className='mb-0'>Ajuste {equipament.equipamentos.entrada.ns}</Form.Label>
                <Form.Control
                  type="number"
                  id="ajuste"
                  aria-describedby="ajuste"
                  onChange={(event) => {
                    equipament.equipamentos.entrada.ajuste = event.target.value;
                    setEquipament({ ...equipament });
                  }}
                  value={equipament.equipamentos.entrada.ajuste}
                  required
                />
              </div>

              <div className="col-sm-4 mt-3">
                <Form.Label className='mb-0'>Número de série Saída</Form.Label>
                <Form.Control
                  type="text"
                  id="ns"
                  aria-describedby="ns"
                  onChange={(event) => {
                    equipament.equipamentos.saida.ns = event.target.value.toUpperCase();
                    setEquipament({ ...equipament });
                  }}
                  value={equipament.equipamentos.saida.ns}
                  required
                />
              </div>

              <div className="col-sm-2 mt-3">
                <Form.Label className='mb-0'>Ajuste {equipament.equipamentos.saida.ns}</Form.Label>
                <Form.Control
                  type="number"
                  id="ajuste"
                  aria-describedby="ajuste"
                  onChange={(event) => {
                    equipament.equipamentos.saida.ajuste = event.target.value;
                    setEquipament({ ...equipament });
                  }}
                  value={equipament.equipamentos.saida.ajuste}
                  required
                />
              </div>

              <div className="col-sm-12 mt-3">
                <Form.Label className='mb-0'>ID da empresa</Form.Label>
                <Form.Select onChange={(event) => {
                  equipament.empresaId = event.target.value;
                  setEquipament({ ...equipament });
                }}
                  aria-label="Default select example">
                  <option></option>
                  {empresaId &&
                    empresaId.map(item => (
                      <option key={item._id} value={item._id}> {item.name} </option>
                    ))}
                </Form.Select>
              </div>
            </Row>
          }

          <Col className="mt-3">
            <ButtonBack />
            <Button style={{ "marginRight": "5px" }} className='col-sm-2' variant="success" size='sm' onClick={() => {
              handleValidateData()
            }}>
              <BsSave2 /> Salvar
            </Button>
          </Col>
        </Card>
      </div>
    </>
  )
}
export default EditarEquipamento

