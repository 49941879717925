import React from "react"
import { Link, useNavigate } from "react-router-dom"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { HiHome } from "react-icons/hi";
import { IoIosExit } from "react-icons/io";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { FaListAlt } from "react-icons/fa";

const NavBar = () => {
  const navigate = useNavigate()
  const usuario = JSON.parse(localStorage.getItem('usuario'))
  const expand = false

  const handleLogout = async () => {
    localStorage.clear()
    navigate('/')
  }

  return (
    <Navbar bg="light" expand={expand} className="mb-3">
      <Container fluid>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <Link style={{ "textDecoration": "none" }} to='/home'>
          {usuario.name ?
            <h3 style={{ "color": "#015431", "margin": "0px" }}>{usuario.name}</h3>
            : null}
        </Link>
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              <Link style={{ "textDecoration": "none" }} to='/home'>
                <h3 className="name-company">Motomco</h3>
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Link to="/home" className="nav-bar"><HiHome /> Home</Link>

              <Link to="/listuser" className="nav-bar"> <FaListAlt /> Listar Usuários</Link>
              <Link to="/listempresa" className="nav-bar"> <FaListAlt /> Listar Empresas</Link>
              <Link to="/listequipamento" className="nav-bar"> <FaListAlt /> Listar Equipamentos</Link>

              <Link to="/adduser" className="nav-bar"> <BsFillPlusCircleFill /> Adicionar Usuário</Link>
              <Link to="/addempresa" className="nav-bar"> <BsFillPlusCircleFill /> Adicionar Empresa</Link>
              <Link to="/addequipamento" className="nav-bar"> <BsFillPlusCircleFill /> Adicionar Equipamento</Link>

              <Link to="/" onClick={() => {
                handleLogout()
              }} className="nav-bar"><IoIosExit /> Sair</Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}
export default NavBar;