import React, { useState } from "react"
import { Row, Card, Form, Button, Col } from "react-bootstrap";
import Api from "../../services/Api";
import { useNavigate } from "react-router-dom";
import { BsSave2 } from "react-icons/bs";
import CnpjMask from "../../components/CnpjMask/CnpjMask";
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import Header from "../../components/Header/Header";
import axios from "axios";

const AddEmpresa = () => {
  const navigate = useNavigate()
  const usuario = JSON.parse(localStorage.getItem('usuario'))
  const token = usuario.token

  const [cidade, setCidade] = useState('')

  const [empresa, setEmpresa] = useState({
    "location": {
      "lat": Number,
      "lng": Number,
      "addr": ""
    },
    "name": "",
    "cnpj": "",
    "password": "",
    "email": "",
    "notification": {
      "notifyTo": [],
      "changeHumidity": false,
      "changeTemperature": false,
      "changePH": false,
      "changeCurve": false,
      "changeMeasure": false,
      "status": false
    },
    "channel": {
      "email": false,
      "app": false,
      "sms": false
    }
  })

  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')

  const handleValidateData = () => {
    if (empresa.name === '' || empresa.cnpj === '' || empresa.email === '' || empresa.addr === '') {
      alert('Preencha todos os campos.')
    } else {
      handleValidate()
    }
  }

  const handleValidate = () => {
    if (password1 === password2) {
      if (password1.length > 4) {
        empresa.password = password1.trim()
        setEmpresa({ ...empresa })
        handleLocation()
      } else {
        alert("Senha muito curta, digite uma senha maior")
      }
    } else {
      alert("Senhas diferentes!")
    }
  }

  const handleLocation = async () => {
    if (!cidade) {
      handlePostEmpresa()
      return
    }
    try {
      const { data } = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${cidade}&key=AIzaSyB9wuoFAr4gCHkvIK30XsxVSEEjaT-VB4g`)
      if (data.status === 'OK' || data.status === 'ok') {
        empresa.location.lat = data.results[0].geometry.location.lat
        empresa.location.lng = data.results[0].geometry.location.lng
        setEmpresa({ ...empresa })
        handlePostEmpresa()
      } else {
        handlePostEmpresa()
        return
      }
    } catch (error) {
      console.log(error)
      handlePostEmpresa()
    }
  }

  const handlePostEmpresa = async () => {
    try {
      const { data } = await Api.post('/empresas/uploadempresas', empresa, { headers: { 'x-access-token': token } })
      if (data) {
        navigate('/listempresa')
      }
    } catch (err) {
      alert(err.response.data)
    }
  }

  return (
    <>
      <Header />
      <div className='container mt-3 mb-3'>
        <Card className='p-3'>
          <h2>Adicionar empresa</h2>
          <Row className="col-sm-12">
            <div className="col-sm-4">
              <Form.Label className='mb-0'>Nome</Form.Label>
              <Form.Control
                type="text"
                id="name"
                aria-describedby="name"
                onChange={(event) => {
                  empresa.name = event.target.value;
                  setEmpresa({ ...empresa });
                }}
                value={empresa.name}
                required
              />
            </div>
            <div className="col-sm-4">
              <Form.Label className='mb-0'>CNPJ</Form.Label>
              <Form.Control
                type="text"
                id="cnpj"
                aria-describedby="cnpj"
                onChange={(event) => {
                  empresa.cnpj = event.target.value;
                  setEmpresa({ ...empresa });
                }}
                value={CnpjMask(empresa.cnpj)}
                required
              />
            </div>
            <div className="col-sm-4">
              <Form.Label className='mb-0'>E-mail</Form.Label>
              <Form.Control
                type="email"
                id="email"
                aria-describedby="email"
                onChange={(event) => {
                  empresa.email = event.target.value;
                  setEmpresa({ ...empresa });
                }}
                value={empresa.email}
                required
              />
            </div>
          </Row>

          <Row className="col-sm-12 mt-3">
            <div className="col-sm-6">
              <Form.Label className='mb-0'>Endereço</Form.Label>
              <Form.Control
                type="text"
                id="addr"
                aria-describedby="addr"
                onChange={(event) => {
                  empresa.location.addr = event.target.value;
                  setEmpresa({ ...empresa });
                }}
                value={empresa.location.addr}
              />
            </div>
            <div className="col-sm-6">
              <Form.Label className='mb-0'>Cidade</Form.Label>
              <Form.Control
                type=""
                id="cidade"
                aria-describedby="cidade"
                onChange={(event) => {
                  setCidade(event.target.value);
                }}
                value={cidade}
              />
            </div>
          </Row>

          {/*           <Row className="col-sm-12 mt-3">
            <div className="col-sm-6">
              <Form.Label className='mb-0'>Latitude</Form.Label>
              <Form.Control
                type="number"
                id="latitude"
                aria-describedby="latitude"
                onChange={(event) => {
                  empresa.location.lat = event.target.value;
                  setEmpresa({ ...empresa });
                }}
                value={empresa.location.lat}
                required
              />
            </div>
            <div className="col-sm-6">
              <Form.Label className='mb-0'>Longitude</Form.Label>
              <Form.Control
                type="number"
                id="longitude"
                aria-describedby="longitude"
                onChange={(event) => {
                  empresa.location.lng = event.target.value;
                  setEmpresa({ ...empresa });
                }}
                value={empresa.location.lng}
                required
              />
            </div>
          </Row> */}

          <Row className="col-sm-12 mt-3">
            <div className="col-sm-6">
              <Form.Label className='mb-0'> Senha </Form.Label>
              <Form.Control
                type="password"
                id="password1"
                aria-describedby="password1"
                onChange={(event) => {
                  let pass1 = event.target.value;
                  setPassword1(pass1);
                }}
                value={password1}
              />
            </div>
            <div className="col-sm-6">
              <Form.Label className='mb-0'>Confirmar senha</Form.Label>
              <Form.Control
                type="password"
                id="password2"
                aria-describedby="password2"
                onChange={(event) => {
                  let pass2 = event.target.value;
                  setPassword2(pass2);
                }}
                value={password2}
              />
            </div>
          </Row>

          <Col className="mt-3">
            <ButtonBack />
            <Button style={{ "marginRight": "5px" }} className='col-sm-2' variant="success" size='sm' onClick={() => {
              handleValidateData()
            }}>
              <BsSave2 /> Salvar
            </Button>
          </Col>
        </Card>
      </div>
    </>
  )
}
export default AddEmpresa