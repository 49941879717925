import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import Api from "../../services/Api"
import { Card, Col, Row, Button, Form, Modal } from 'react-bootstrap';
import { BsSave2 } from "react-icons/bs";
import { MdOutlineManageAccounts } from "react-icons/md";

import ButtonBack from "../../components/ButtonBack/ButtonBack";
import CnpjMask from "../../components/CnpjMask/CnpjMask";
import Header from "../../components/Header/Header";
import axios from 'axios';


const EditarEmpresa = (props) => {
  const { _id } = useParams()
  const navigate = useNavigate()
  const usuario = JSON.parse(localStorage.getItem('usuario'))
  const token = usuario.token
  const [empresa, setEmpresa] = useState()
  const [emailManager, setEmailManager] = useState('')
  const [error, setError] = useState('')
  const [emailManag, setEmailManag] = useState('')

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClean = () => {
    setEmailManag('')
    setEmailManager('')
  }

  const handleEmpresa = async () => {
    try {
      const { data } = await Api.get(`/empresas/listempresas/${_id}`, { headers: { 'x-access-token': token } })
      data ? setEmpresa(data) : setEmpresa()
    } catch (err) {
      alert('Erro ao listar empresa.')
    }
  }

  const handleValidateData = () => {
    if (empresa.name === '' || empresa.cnpj === '' || empresa.email === '' || empresa.addr === '') {
      alert('Preencha todos os campos.')
    } else {
      handlePutEmpresa()
    }
  }

  const handlePutEmpresa = async () => {
    try {
      const { data } = await Api.put('/empresas/updateempresas', empresa, { headers: { 'x-access-token': token } })
      if (data) {
        navigate('/listempresa')
      }
    } catch (err) {
      alert(err.response.data)
    }
  }

  const handleEmail = async () => {
    try {
      const data = await axios.post('/email', { headers: { "email": emailManag } })
      data.data.email ? setEmailManager(data.data) : setError("E-mail não encontrado.")
    } catch (error) {
      setError("E-mail não encontrado.")
      alert("E-mail não encontrado.")
    }
  }

  const handleEmailPut = async () => {
    try {
      const data = await axios.post('/addmanagermonitora', { "email": emailManag, companyId: _id });
      if (data.status === 200) {
        alert(data.data.msg)
        handleClean()
      } else {
        alert("Erro.")
        return
      }
    } catch (error) {
      alert("Erro.", error)
    }
  }


  useEffect(() => {
    handleEmpresa()
  }, [])

  const modalAddGestor = (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Adicione aqui um Gestor para a empresa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
                onChange={e => {
                  //valorEmail = e.target.value
                  setEmailManag(e.target.value)
                }}
                value={emailManag}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"

            >
              <Form.Label><strong>{emailManager.name}</strong></Form.Label>
            </Form.Group>

          </Form>
        </Modal.Body>

        {!emailManager.email ?
          <Modal.Footer>
            <Button variant="primary" size='sm' onClick={handleEmail}>
              Buscar usuário
            </Button>
          </Modal.Footer>
          :
          <Modal.Footer>
            <Button variant="secondary" size='sm' onClick={() => { handleClean() }}>
              Limpar
            </Button>
            <Button variant="secondary" size='sm' onClick={() => {
              handleClose()
              handleClean()
            }}>
              Fechar
            </Button>
            <Button variant="primary" size='sm' onClick={() => { handleEmailPut() }}>
              Adicionar
            </Button>
          </Modal.Footer>
        }

      </Modal>
    </>
  )

  return (
    <>
      <Header />
      {modalAddGestor}
      <div className='container mt-3 mb-3'>
        <Card className='p-3'>
          <h2>Editar empresa</h2>
          {empresa &&
            <div>
              <Row className="col-sm-12">
                <div className="col-sm-4">
                  <Form.Label className='mb-0'>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    aria-describedby="name"
                    onChange={(event) => {
                      empresa.name = event.target.value;
                      setEmpresa({ ...empresa });
                    }}
                    value={empresa.name}
                    required
                  />
                </div>
                <div className="col-sm-4">
                  <Form.Label className='mb-0'>CNPJ</Form.Label>
                  <Form.Control
                    type="text"
                    id="cnpj"
                    aria-describedby="cnpj"
                    onChange={(event) => {
                      empresa.cnpj = event.target.value;
                      setEmpresa({ ...empresa });
                    }}
                    value={CnpjMask(empresa.cnpj)}
                    required
                  />
                </div>
                <div className="col-sm-4">
                  <Form.Label className='mb-0'>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    aria-describedby="email"
                    onChange={(event) => {
                      empresa.email = event.target.value;
                      setEmpresa({ ...empresa });
                    }}
                    value={empresa.email}
                    required
                  />
                </div>
              </Row>
              <Row className="col-sm-12 mt-3">
                <div className="col-sm-12">
                  <Form.Label className='mb-0'>Endereço</Form.Label>
                  <Form.Control
                    type="text"
                    id="addr"
                    aria-describedby="addr"
                    onChange={(event) => {
                      empresa.location.addr = event.target.value;
                      setEmpresa({ ...empresa });
                    }}
                    value={empresa.location.addr}
                  />
                </div>
              </Row>






              <Row className="col-sm-12 mt-3">
                <div className="col-sm-6">
                  <Form.Label className='mb-0'>Latitude</Form.Label>
                  <Form.Control
                    type="number"
                    id="latitude"
                    aria-describedby="latitude"
                    onChange={(event) => {
                      empresa.location.lat = event.target.value;
                      setEmpresa({ ...empresa });
                    }}
                    value={empresa.location.lat}
                    required
                  />
                </div>
                <div className="col-sm-6">
                  <Form.Label className='mb-0'>Longitude</Form.Label>
                  <Form.Control
                    type="number"
                    id="longitude"
                    aria-describedby="longitude"
                    onChange={(event) => {
                      empresa.location.lng = event.target.value;
                      setEmpresa({ ...empresa });
                    }}
                    value={empresa.location.lng}
                    required
                  />
                </div>
              </Row>






            </div>
          }

          <Col className="mt-3">
            <ButtonBack />
            <Button style={{ "marginRight": "5px" }} className='col-sm-2' variant="success" size='sm' onClick={() => {
              handleValidateData()
            }}>
              <BsSave2 /> Salvar
            </Button>
            <Button style={{ "marginRight": "5px" }} className='col-sm-2' variant="warning" size='sm' onClick={() => {
              setShow(true)
            }}>
              <MdOutlineManageAccounts /> Adicionar Gestor
            </Button>
          </Col>
        </Card>
      </div>
    </>
  )
}
export default EditarEmpresa