import React, { useState, useEffect } from "react"
import { Row, Card, Form, Button, Col } from "react-bootstrap";
import Api from "../../services/Api";
import { useNavigate } from "react-router-dom";
import { BsSave2 } from "react-icons/bs";
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import Header from "../../components/Header/Header";

const AddEquipamento = (props) => {
  const navigate = useNavigate()

  const usuario = JSON.parse(localStorage.getItem('usuario'))
  const token = usuario.token

  const [empresaId, setEmpresaId] = useState()

  const [equipament, setEquipament] = useState({
    "equipamentos": {
      "entrada": {
        "configs": {
          "maxHumidity": 20,
          "minHumidity": 10,
          "minTemperature": 10,
          "maxTemperature": 30,
          "minPH": 600,
          "maxPH": 800
        },
        "ns": "",
        "ajuste": 0,
      },
      "saida": {
        "configs": {
          "maxHumidity": 20,
          "minHumidity": 10,
          "minTemperature": 10,
          "maxTemperature": 30,
          "minPH": 600,
          "maxPH": 800
        },
        "ns": "",
        "ajuste": 0,
      }
    },
    "name": "",
    "curva": "",
    "empresaId": "",
    "sha1Curva": ""
  })

  const handlePostEquipament = async () => {
    try {
      const { data } = await Api.post('/instruments/uploadeinstruments', equipament, { headers: { 'x-access-token': token } })
      if (data) {
        navigate('/listequipamento')
      }
    } catch (err) {
      alert(err.response.data)
    }
  }

  const handleEmpredaId = async () => {
    try {
      const { data } = await Api.get('/empresas/listempresas', { headers: { 'x-access-token': token } })
      data ? setEmpresaId(data) : setEmpresaId([])
    } catch (err) {
      alert('Erro ao listar equipamento.')
    }
  }

  const handleValidateData = () => {
    if (equipament.name === '' || equipament.empresaId === '') {
      alert('Preencha todos os campos.')
    } else {
      handlePostEquipament()
    }
  }

  useEffect(() => {
    handleEmpredaId()
  }, [])

  return (
    <>
      <Header />
      <div className='container mt-3 mb-3'>
        <Card className='p-3'>
          <h2>Adicionar equipamento</h2>
          <Row className="col-sm-12">
            <div className="col-sm-12">
              <Form.Label className='mb-0'>Local de instalação</Form.Label>
              <Form.Control
                type="text"
                id="name"
                aria-describedby="name"
                onChange={(event) => {
                  equipament.name = event.target.value;
                  setEquipament({ ...equipament });
                }}
                value={equipament.name}
                required
              />
            </div>

            <div className="col-sm-6 mt-3">
              <Form.Label className='mb-0'>Número de série Entrada</Form.Label>
              <Form.Control
                type="text"
                id="ns"
                aria-describedby="ns"
                onChange={(event) => {
                  equipament.equipamentos.entrada.ns = event.target.value.toUpperCase();
                  setEquipament({ ...equipament });
                }}
                value={equipament.equipamentos.entrada.ns}
                required
              />
            </div>
            <div className="col-sm-6 mt-3">
              <Form.Label className='mb-0'>Número de série Saída</Form.Label>
              <Form.Control
                type="text"
                id="ns"
                aria-describedby="ns"
                onChange={(event) => {
                  equipament.equipamentos.saida.ns = event.target.value.toUpperCase();
                  setEquipament({ ...equipament });
                }}
                value={equipament.equipamentos.saida.ns}
                required
              />
            </div>

            <div className="col-sm-12 mt-3">
              <Form.Label className='mb-0'>Empresa</Form.Label>
              <Form.Select onChange={(event) => {
                equipament.empresaId = event.target.value;
                setEquipament({ ...equipament });
              }}
                aria-label="Default select example">
                <option></option>
                {empresaId &&
                  empresaId.map(item => (
                    <option key={item._id} value={item._id}>{item.name}</option>
                  ))}
              </Form.Select>
            </div>

          </Row>
          <Col className="mt-3">
            <ButtonBack />
            <Button style={{ "marginRight": "5px" }} className='col-sm-2' variant="success" size='sm' onClick={() => {
              handleValidateData()
            }}>
              <BsSave2 /> Salvar
            </Button>
          </Col>
        </Card>
      </div>
    </>
  )
}
export default AddEquipamento