import React, { useState } from "react"
import Api from "../../services/Api"
import { Card } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import ListEquipamrntoForm from "./components/ListEquipamentoForm"
import { useEffect } from "react";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom"
import Button from 'react-bootstrap/Button';
import { AiOutlinePlusCircle } from "react-icons/ai";
import ButtonBack from "../../components/ButtonBack/ButtonBack";

const ListEquipamento = (props) => {
  const navigate = useNavigate()
  const usuario = JSON.parse(localStorage.getItem('usuario'))
  const token = usuario.token
  const [equipamentos, setEquipamentos] = useState()

  const handleListEquipaments = async () => {
    try {
      const { data } = await Api.get('/instruments/listinstruments', { headers: { 'x-access-token': token } })
      data ? setEquipamentos(data) : setEquipamentos([])
    } catch (err) {
      alert('Erro ao listar equipamentos.')
    }
  }

  useEffect(() => {
    handleListEquipaments()
  }, [])

  return (
    <>
      <Header />
      <div className='container mt-3 mb-3'>
        <Card className='p-3 mb-3'>
          <h2>Lista de equipamentos cadastrados</h2>
          <Table striped bordered hover style={{ "background": "white", "borderRadius": "5px", "border": "transparent" }}>
            <thead style={{ "background": "rgba(41, 140, 101, 0.6)" }}>
              <tr>
                <th className='col-sm-3' title=" Nome da empresa que o equipamento está relacionado."> Nome da empresa </th>
                <th className='col-sm-3' title=" Local de instalação."> Local de instalação </th>
                <th className='col-sm-2' title=" Número de série de entrada."> NS de entrada </th>
                <th className='col-sm-2' title=" Número de série de saída."> NS de saída </th>
                <th className='col-sm-1 text-center'> Editar </th>
                <th className='col-sm-1 text-center'> Apagar </th>
              </tr>
            </thead>
            <tbody>
              {equipamentos &&
                equipamentos.map((item) => (
                  <ListEquipamrntoForm key={item._id} handleListEquipaments={handleListEquipaments} data={item} />
                ))
              }
            </tbody>
          </Table>
        </Card>
        <ButtonBack />
        <Button className='col-sm-2' variant="success" size='sm' onClick={() => { navigate('/addequipamento') }} >
          <AiOutlinePlusCircle /> Adicionar
        </Button>
      </div>
    </>
  )
}
export default ListEquipamento
