import React, { useState } from "react"
import { Row, Card, Form, Button, Col } from "react-bootstrap";
import Api from "../../services/Api";
import { useNavigate } from "react-router-dom";
import { BsSave2 } from "react-icons/bs";
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import Header from "../../components/Header/Header";

const AddUser = () => {
  const navigate = useNavigate()
  const usuario = JSON.parse(localStorage.getItem('usuario'))
  const token = usuario.token

  const [user, setUser] = useState({
    "name": "",
    "email": "",
    "password": ""
  })

  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')

  const handleValidateData = () => {
    if (user.name === '' || user.email === '') {
      alert('Preencha todos os campos.')
    } else {
      handleValidate()
    }
  }

  const handleValidate = () => {
    if (password1 === password2) {
      if (password1.length > 4) {
        user.password = password1
        setUser({ ...user })
        handlePostUser()
      } else {
        alert("Senha muito curta, digite uma senha maior")
      }
    } else {
      alert("Senhas diferentes!")
    }
  }

  const handlePostUser = async () => {
    try {
      const { data } = await Api.post('/usersadmin/uploadusersadmin', user, { headers: { 'x-access-token': token } })
      if (data) {
        navigate('/listuser')
      }
    } catch (err) {
      alert('Erro')
    }
  }

  return (
    <>
      <Header />
      <div className='container mt-3 mb-3'>
        <Card className='p-3'>
          <h2>Adicionar usuário</h2>
          <Row className="col-sm-12">
            <div className="col-sm-6">
              <Form.Label className='mb-0'>Nome</Form.Label>
              <Form.Control
                type="text"
                id="name"
                aria-describedby="name"
                onChange={(event) => {
                  user.name = event.target.value;
                  setUser({ ...user });
                }}
                value={user.name}
                required
              />
            </div>
            <div className="col-sm-6">
              <Form.Label className='mb-0'>E-mail</Form.Label>
              <Form.Control
                type="email"
                id="email"
                aria-describedby="email"
                onChange={(event) => {
                  user.email = event.target.value;
                  setUser({ ...user });
                }}
                value={user.email}
                required
              />
            </div>
          </Row>
          <Row className="col-sm-12 mt-3">
            <div className="col-sm-6">
              <Form.Label className='mb-0'> Senha </Form.Label>
              <Form.Control
                type="password"
                id="password1"
                aria-describedby="password1"
                onChange={(event) => {
                  let pass1 = event.target.value;
                  setPassword1(pass1);
                }}
                value={password1}
              />
            </div>
            <div className="col-sm-6">
              <Form.Label className='mb-0'>Confirmar senha</Form.Label>
              <Form.Control
                type="password"
                id="password2"
                aria-describedby="password2"
                onChange={(event) => {
                  let pass2 = event.target.value;
                  setPassword2(pass2);
                }}
                value={password2}
              />
            </div>
          </Row>
          <Col className="mt-3">
            <ButtonBack />
            <Button style={{ "marginRight": "5px" }} className='col-sm-2' variant="success" size='sm' onClick={() => {
              handleValidateData()
            }}>
              <BsSave2 /> Salvar
            </Button>
          </Col>
        </Card>
      </div>
    </>
  )
}
export default AddUser