import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import Api from "../../services/Api"
import { Card, Col, Row, Button, Form } from 'react-bootstrap';
import { BsSave2 } from "react-icons/bs";
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import Header from "../../components/Header/Header";

const EditarUser = (props) => {
  const { _id } = useParams()
  const navigate = useNavigate()

  const usuario = JSON.parse(localStorage.getItem('usuario'))
  const token = usuario.token
  const [user, setUser] = useState()

  const handleEmpresa = async () => {
    try {
      const { data } = await Api.get(`/usersadmin/listusersadmin/${_id}`, { headers: { 'x-access-token': token } })
      data ? setUser(data) : setUser()
    } catch (err) {
      alert('Erro ao listar usuário.')
    }
  }

  const handleValidateData = () => {
    if (user.name === '' || user.email === '') {
      alert('Preencha todos os campos.')
    } else {
      handlePutEmpresa()
    }
  }

  const handlePutEmpresa = async () => {
    try {
      const { data } = await Api.put('/usersadmin/updateusersadmin', user, { headers: { 'x-access-token': token } })
      if (data) {
        navigate('/listuser')
      }
    } catch (err) {
      alert('Erro')
    }
  }
  useEffect(() => {
    handleEmpresa()
  }, [])

  return (
    <>
      <Header />
      <div className='container mt-3 mb-3'>
        <Card className='p-3'>
          <h2>Editar usuário</h2>
          {user &&
            <div>
              <Row className="col-sm-12">
                <div className="col-sm-6">
                  <Form.Label className='mb-0'>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    aria-describedby="name"
                    onChange={(event) => {
                      user.name = event.target.value;
                      setUser({ ...user });
                    }}
                    value={user.name}
                    required
                  />
                </div>
                <div className="col-sm-6">
                  <Form.Label className='mb-0'>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    aria-describedby="email"
                    onChange={(event) => {
                      user.email = event.target.value;
                      setUser({ ...user });
                    }}
                    value={user.email}
                    required
                  />
                </div>
              </Row>
            </div>
          }
          <Col className="mt-3">
            <ButtonBack />
            <Button style={{ "marginRight": "5px" }} className='col-sm-2' variant="success" size='sm' onClick={() => {
              handleValidateData()
            }}>
              <BsSave2 /> Salvar
            </Button>
          </Col>
        </Card>
      </div>
    </>
  )
}
export default EditarUser
